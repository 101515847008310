body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-weight: 400;
  line-height: 1.5; 
}

td, th {
  text-align: left;
  padding-left: 12px;
}

.success-text{
  text-align: center;
    padding: 17px;
    color: green;
    font-size: large;
}

/* override Class */
/* .MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px !important;
} */

.swal-title {
  font-size: 20px !important;
}

.client-app-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.logo-image-container{
  width: 100px;
  height: 50px;
}

.logo-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.hideForm{
  display: none
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(9px, -7px) scale(0.85) !important;
}